import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { ProductosListar } from './ProductosListar';
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast, faCreditCard, faCertificate, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { Contacto } from './Contacto';
import { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './../estilo/styles.css';
// import required modules
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/effect-fade';
import Zoom from '@mui/material/Zoom';


import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';


export const Home = ({ productos, preciosVariables, getProductos, getIdProducto1, productosCategorias, getCategoria, categoria, setMiCarrito, PreciosPlanes }) => {

  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  const [titulo2, setTitulo2] = React.useState(false);
  const [titulo1, setTitulo1] = React.useState(false);
  const containerRef = React.useRef(null);

  return (
    <>
      <section >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          effect={'fade'}
          autoplay={{
            delay: 12000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}

          onAutoplayTimeLeft={onAutoplayTimeLeft}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, EffectFade, Pagination, Navigation]}
          className="mySwiper w-full"
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={(swiper) => {
            if (swiper.realIndex == 1) {
              //console.log("numero slider-1")
              setTitulo2(true);
              setTitulo1(false);
              //handleChange();
            }
            if (swiper.realIndex == 0) {
              //console.log("numero slider-0")
              setTitulo2(false);
              setTitulo1(true);
              //handleChange();
            }
            //console.log("numero slider" + swiper.realIndex)
            //console.log(swiper)
          }}
        >
          <SwiperSlide >
            <div className=' w-full h-[400px] lg:h-[505px] px-4 py-9 lg:p-10 [background:radial-gradient(125%_125%_at_50%_10%,#d6ebff_40%,#3C9AF4_100%)] dark:[background:radial-gradient(125%_125%_at_50%_10%,#04040c_40%,#112461_100%)]'>
              <Grow
                in={titulo1}
                style={{ transformOrigin: 'left center' }}
                {...(titulo1 ? { timeout: 1000 } : {})}
              >
                <h1 className=' titulo1 text-center p-1 '>Comprá tu tienda virtual</h1>
              </Grow>
              <div >
                <Slide in={titulo1} timeout={1000} direction="right"   >
                  <div>
                    <Fade in={titulo1} timeout={3000} ><p className='text-center font-bold dark:font-[10] '>Conectá con nuevos clientes y aumentá tus ventas.</p></Fade>
                  </div>
                </Slide>
              </div>
              <div className='flex justify-center'>
                <div className='py-3 lg:py-7 px-7 lg:p-0 '>
                  <Zoom in={titulo1} style={{ transitionDelay: titulo1 ? '500ms' : '0ms' }}>
                    <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/inicio.png"} className="max-h-48 lg:max-h-60" alt="dispositivos" />
                  </Zoom>
                </div>
              </div>
              <div className='hidden'> *tipo  git , instagram@, css animacion</div>
            </div>
          </SwiperSlide>
          <SwiperSlide >
            <div className=' w-full h-[400px] lg:h-[505px] px-4 py-9 lg:p-10 [background:radial-gradient(125%_125%_at_50%_10%,#d6ebff_40%,#3C9AF4_100%)] dark:[background:radial-gradient(125%_125%_at_50%_10%,#04040c_40%,#112461_100%)]'>
              {/*
              <div class="contenedor1 h-full">
                <div class="a">Contenido A</div>
                <div class="b">Contenido B</div>
                <div class="c">Contenido C</div>
              </div>

           */}
              <div className=' h-full flex-col flex '>
                <div>
                  <Grow
                    in={titulo2}
                    style={{ transformOrigin: 'left center' }}
                    {...(titulo2 ? { timeout: 1000 } : {})}
                  >
                    <h1 className=' titulo1 text-center p-1  '>Publicidad en redes sociales</h1>
                  </Grow>
                </div>
                <div>
                  <Slide in={titulo2} timeout={1000} direction="right"   >
                    <div>
                      <Fade in={titulo2} timeout={3000} ><p className='text-center font-bold dark:font-[10] '>Anunciá tus productos o servicios, tu negocio en internet.</p></Fade>
                    </div>
                  </Slide>
                </div>

                <div className='grow  flex justify-center  items-center'>
                  <div className=' px-5 '>
                    <div className='inline-block '>

                      <div className=" flex grid grid-cols-4 place-content-center gap-5 lg:gap-12 justify-items-center items-center ">

                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '500ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/instagram.svg"} className='h-full' alt="Instagram logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '600ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/facebook.svg"} className='h-full' alt="Facebook logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '700ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/google.svg"} className='h-full' alt="Google logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '800ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/googlemaps.svg"} className='h-full' alt="Google Maps logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '900ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/whatsapp.svg"} className='h-full' alt="WhatsApp logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '1000ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/youtube.svg"} className='h-full' alt="YouTube logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '1100ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/tiktok.svg"} className='h-full' alt="TikTok logo" />
                          </Zoom>
                        </div>
                        <div className='w-full h-8 lg:h-12 flex justify-center items-center'>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '1200ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/x.svg"} className='block dark:hidden h-full' alt="X logo" />
                          </Zoom>
                          <Zoom in={titulo2} style={{ transitionDelay: titulo2 ? '1300ms' : '0ms' }}>
                            <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/x_dark.svg"} className='!hidden dark:!block h-full' alt="X logo" />
                          </Zoom>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>



            </div>

          </SwiperSlide>
          <div className="autoplay-progress" slot="container-end">
            <svg viewBox="0 0 48 48" ref={progressCircle}>
              <circle cx="24" cy="24" r="20"></circle>
            </svg>
            <span ref={progressContent}></span>
          </div>
        </Swiper >
      </section >



      <ProductosListar productos={productos} preciosVariables={preciosVariables} getProductos={getProductos} getIdProducto1={getIdProducto1} getCategoria={getCategoria} setMiCarrito={setMiCarrito} PreciosPlanes={PreciosPlanes} />
      <section className='hidden margen1 bg-[#F8FAFF] dark:bg-[#050610]'>
        <h1 className='titulo1 text-center'>
          Portafolio
        </h1>
        <div className='mt-[3rem] mb-7'>
          xyz<br />
          imagenes de tienda brando
        </div>
      </section>
      <section className='margen1 bg-[#F8FAFF] dark:bg-[#050610]'>
        <h1 className='titulo1 text-center'>
          Nuestros clientes
        </h1>
        <div className='mt-[3rem] mb-7'>

          <div className='flex justify-center '>


            <div className="flex grid grid-cols-1 lg:grid-cols-3 gap-16 lg:gap-16 justify-items-center items-center">
              <div > <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/pepsi.svg"} className='pepsi' alt="Pepsi" /></div>
              <div ><img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/unilever.svg"} className='unilever' alt="Unilever" /></div>
              <div > <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/afa.png"} className='afa' alt="A.F.A" /></div>
              <div > <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/Logo_Banco_Supervielle.svg"} className='supervielle' alt="Banco Supervielle" /></div>
              <div > <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/tiendabrando.svg"} className='tiendabrando' alt="TiendaBrando" /></div>
              <div  > <img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/correo-argentino.svg"} className='correoArgentino' alt="Correo argentino" /></div>
            </div>
          </div>
        </div>
      </section>
      <section className='publicidad lg:px-8 py-2'>
        <div className='text-center text-xs pb-2'>
          PUBLICIDAD
        </div>
        <div className='adsense'>
          {/*
          Publicidad
          */}
        </div>
      </section>
      <Contacto />
    </ >
  )
}

