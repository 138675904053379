import { Fragment, useEffect, useState, React } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Routes, Route } from "react-router-dom";
import { Layaut } from './components/Layaut';
import { Productos } from './components/Productos';
import { ProductosDetalle } from './components/ProductosDetalle';
import { Home } from './components/Home';
import { Opps } from './components/Opps';
import './estilo/estilo.css';
//import './estilo/estilo-bootstrap.css';
import { Outlet, Link } from "react-router-dom";
import { Carrito } from './components/Carrito';
import { Contacto } from './components/Contacto';
import { useParams } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCartShopping, faPhone, faLocationDot, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import ReactWhatsappButton from "react-whatsapp-button";
import logo from "./imagenes/powerbit.svg";
import RegisterForm from "./components/RegisterForm";
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Nav } from "./components/Nav";
import confetti from "canvas-confetti";
import { NumericFormat } from 'react-number-format';







function App() {

  const { idProducto } = useParams();
  console.log("++++++")
  console.log(idProducto)
  const año = "2023";
  const mivariable = "perro14";
  const [productos, setProductos] = useState([]);
  const [preciosVariables, setPreciosVariables] = useState([]);
  const [links, setLinks] = useState([]);
  const [productosCategorias, setProductosCategorias] = useState([]);
  const [onEdit, setOnEdit] = useState(null);
  const [onEdit1, setOnEdit1] = useState(null);
  const [nombre1, setNombre] = useState(0);
  const [filtro, setFiltro] = useState(0);
  const [filtroOrden, setFiltroOrden] = useState('Menor precio');
  const [ordenar, setOrdenar] = useState('nombre');
  const [categoria, setCategoria] = useState({ id: 1, nombre: 'Ceramicos' });
  const [idProducto1, setIdProducto1] = useState(idProducto);
  const [ProductoXid, setProductoXid] = useState([]);


  //const [idCategoria, setIdCategoria] = useState(3);
  const [parmsIdProducto, setParmsIdProducto] = useState(null);
  const [MiCarrito, setMiCarrito] = useState([]);
  const [MiCarritoTOTAL, setMiCarritoTOTAL] = useState(0);
  const [productosDescatados, setProductosDestacados] = useState([]);
  const [sucursales, setSucursales] = useState(0);
  const [envioFlete, setEnvioFlete] = useState(false);
  const [checked, setChecked] = useState(false);

  const location = useLocation();
  const lastHash = useRef('');
  const [dark, setDark] = useState(false);





  const formatoPrecio = (precio, redondear) => {
    //let price = 14340.8;
    let ARSpesos = new Intl.NumberFormat('es-AR', {
      currency: 'ARS',
    });
    //console.log(`The formated version of ${price} is ${USDollar.format(price)}`);
    /*
      let number = 999999999.99;
      number.toLocaleString('es-AR', { currency: 'ARS', style: 'currency' });
  */
    let total;
    let redondeo = Math.ceil(precio / 100) * 100;

    if (redondeo == 0) {
      total = ARSpesos.format(precio);
    } else {
      total = ARSpesos.format(redondeo);
    }
    /*
        console.log("###")
        console.log("precio: ")
        console.log(precio)
        console.log("redondeo: ")
        console.log(redondeo)
    */
    return total;
  }

  const getFiltroOrden = (nuevo) => {
    //alert(nuevoNombre);
    setFiltroOrden(nuevo);
  }
  const getEnvioFlete = (nuevo) => {
    //alert(nuevoNombre);
    setEnvioFlete(nuevo);
  }
  const getMiCarritoTOTAL = (nuevo) => {
    //alert(nuevoNombre);
    var tot = 0;
    MiCarrito.map((MiCarro, index) => tot = tot + MiCarro.precioTOT);
    setMiCarritoTOTAL(tot);
  }
  const getSucursales = (nuevo) => {
    //alert(nuevoNombre);
    setSucursales(nuevo);
  }

  const getMiCarrito = (nuevo) => {
    //alert(nuevoNombre);
    setMiCarrito(nuevo);
    console.log(MiCarrito);
  }
  const agregarCarrito = () => {
    //alert("sip");
  }

  const getCategoria = (nuevoNombre) => {
    //alert(nuevoNombre);
    setCategoria(nuevoNombre);
  }
  const cambiarNombre = (nuevoNombre) => {
    setNombre(nuevoNombre);
    setOnEdit(null);
  }
  const cambiarFiltro = (nuevoNombre) => {
    setFiltro(nuevoNombre);
    getLinks();
  }
  const cambiarOrdenar = (nuevoNombre) => {
    setOrdenar(nuevoNombre);
    getLinks();
  }
  const getParmsIdProducto = (nuevo) => {
    setParmsIdProducto(nuevo);
    console.log(nuevo);
  }
  const getIdProducto1 = (nuevo) => {
    setIdProducto1(nuevo);
    //console.log(nuevo);
  }
  /*
    const getIdCategoria = (nuevo) => {
      setIdCategoria(nuevo);
      //console.log(nuevo);
    }
  */
  const getProductos = async () => {
    //alert("aqui prodcutps lista");
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/productos/" + categoria.id + "/" + filtroOrden);
      //alert("aqui2");
      console.log("$$$$$")
      console.log(res.data)
      setProductos(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };
  const getPreciosVariables = async () => {
    //alert("aqui prodcutps lista");
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/preciosVariables/");
      //alert("aqui2");
      console.log("preciosvariables")
      console.log(res.data)
      setPreciosVariables(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };
  const getProductosDestacados = async () => {
    //alert("aqui prodcutps lista");
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/productosDestacados/");
      //alert("aqui2");
      setProductosDestacados(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };

  //DUPLICADO
  const getProductosId = async () => {
    //alert("::::::::::" + idProducto1 + " * " + categoria.id);

    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/producto/" + idProducto1);
      //alert("aqui2");
      console.log("????");
      console.log(res.data);
      console.log(idProducto1);
      //console.log(idCategoria);
      setProductoXid(res.data);//.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
    } catch (error) {
      toast.error(error);
    }
  };

  const getLinks = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/links/categoria/" + filtro + "/orden/" + ordenar);
      setLinks(res.data);
      //alert("set link");
    } catch (error) {
      toast.error(error);
    }
  };
  const getProductosCategorias = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_URL_API + "/categorias/");
      setProductosCategorias(res.data);
      //alert("set link");
    } catch (error) {
      toast.error(error);
    }
  };
  const ObjectLength = () => {
    var length = 0;
    for (var key in MiCarrito) {
      if (MiCarrito.hasOwnProperty(key)) {
        ++length;
      }
    }
    return length;
  }

  const PreciosPlanes = (id) => {


    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    console.log(preciosVariables);

    var precioTotal = 0;
    let webHosting = preciosVariables[6]["precio"];
    let dominio1año = preciosVariables[4]["precio"];
    let sueldoMioConGanancia = preciosVariables[7]["precio"];
    let ssl = preciosVariables[8]["precio"];
    let cloudHosting = preciosVariables[2]["precio"];
    let anuncioPatrocinadoGoogle = preciosVariables[3]["precio"];
    console.log("!!!")
    console.log(webHosting);
    //console.log(formatoPrecio(webHosting));

    if (id == 2) {
      precioTotal = webHosting + dominio1año + ((sueldoMioConGanancia / 2) / 2) + ssl;
      //precioTotal = 0;
    }
    if (id == 3) {
      //precioTotal = 0;
      precioTotal = cloudHosting + dominio1año + (sueldoMioConGanancia / 2) + ssl + anuncioPatrocinadoGoogle;
    }
    if (id == 1) {
      precioTotal = 0;
    }
    console.log(precioTotal + "precioTotal--------------------------------------")
    return formatoPrecio(precioTotal, 1);
  }



  const confites = () => {
    setTimeout(() => {
      confetti({
        spread: 2500,
        origin: { y: 0 },
        particleCount: 200,
        startVelocity: 22,
        angle: 120,
        //spread: 90,
        // origin: { x: 1 }
        /*
        origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
        }
        angle: 120,
        */
      });
    }, 1000);
  }



  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 0);
    }
  }, [location]);



  useEffect(() => {
    getProductos();
    getPreciosVariables();
    //getProductos();
    //getProductosId();
  }, [filtroOrden]);//setProductos

  useEffect(() => {
    getProductosCategorias();
    //confites();
    //getProductos();
    //getProductosId();
  }, []);//setProductos
  useEffect(() => {
    getMiCarritoTOTAL();
    console.log("+++")
    console.log(MiCarrito)
    //getProductos();
    //getProductosId();
  }, [MiCarrito]);//setProductos

  /*
    useEffect(() => {
      getLinks();
    }, [links]);
    useEffect(() => {
      getLinksCategorias();
    }, [linksCategorias]);
  
  */
  return (
    <>
      <div className="bg-[#e4ebf6] dark:bg-black w-full min-h-screen">
        <div className="2xl:container bg-white mx-auto text-[#000] dark:text-[#c3c3cc] ">
          <Nav MiCarrito={MiCarrito} ObjectLength={ObjectLength} dark={dark} setDark={setDark} />
          <main>
            <Routes>
              <Route path="/" element={<Layaut />}>
                <Route path={process.env.REACT_APP_URL_FOLDER + "/"} element={<Home productos={productosDescatados} preciosVariables={preciosVariables} getProductos={getProductosDestacados} getIdProducto1={getIdProducto1} productosCategorias={productosCategorias} getCategoria={getCategoria} categoria={categoria} setMiCarrito={setMiCarrito} PreciosPlanes={PreciosPlanes} />} /> {/* productos={productos} getProductos={getProductos} getIdProducto1={getIdProducto1} getCategoria={getCategoria} categoria={categoria} productosCategorias={productosCategorias} */}
                <Route path={process.env.REACT_APP_URL_FOLDER + "/carrito"} element={<Carrito checked={checked} setChecked={setChecked} ObjectLength={ObjectLength} envioFlete={envioFlete} getEnvioFlete={getEnvioFlete} MiCarrito={MiCarrito} setMiCarrito={setMiCarrito} MiCarritoTOTAL={MiCarritoTOTAL} getMiCarrito={getMiCarrito} formatoPrecio={formatoPrecio} />} />
                <Route path="*" element={<Opps />} />
              </Route>
            </Routes>
          </main>

          <footer className="footer bg-[#F6F7F8] text-black dark:bg-[#050610] dark:text-[#8da1ba] margen1 bg-neutral text-neutral-content">

            <div className=" gap-4">


              <div>
                <div className="flex justify-center">
                  <div>
                    <img class="block dark:hidden h-8 w-auto" src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/powerbit-light.svg"} alt="PowerBit Logo" />
                    <img class="hidden dark:block h-8 w-auto" src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/powerbit-dark.svg"} alt="PowerBit Logo" />
                  </div>
                </div>
                <p className="text-center font-semibold dark:font-[10] pie_rights">© {new Date().getFullYear()}. Todos los derechos reservados.</p>
              </div>

            </div>

          </footer>
        </div>
        <ReactWhatsappButton
          countryCode="54"
          phoneNumber="91125870303"
          animated
          style={{/*


  phoneNumber="91125870303"

          backgroundColor: "#00a4f5",
          right: "unset",
          left: "10px",*/

          }}
          message="Hola, quiero hacer una pregunta..."
        />{/*animated*/}
        <ToastContainer autoClose={3000} position={toast.POSITION.BOTTOM_RIGHT} />

      </div>
    </>

  );
}
export default App;