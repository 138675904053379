import React from 'react';
import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import {
    CheckIcon
} from '@heroicons/react/24/solid'



export const ProductosListar = ({ productos, preciosVariables, getProductos, getIdProducto1, getCategoria, rango1, rango2, setMiCarrito, PreciosPlanes }) => {

    const { idProducto } = useParams();

    const handleClick = (nombre, precio, id, imagen, unidad) => {
        //alert(precio);
        setMiCarrito(current => [...current, { id: id, nombre: nombre, imagen: imagen, precio: precio, cantidad: 1, precioTOT: parseInt(precio.replace('.', '')), unidad: unidad }]);
    };

    const primeraLetraMayuscula = (cadena) => {
        return cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length))
    };



    //const productos1 = productos['productos'];
    console.log("////");
    console.log(productos);
    useEffect(() => {
        getProductos();
    }, []);


    const cambiatetete = (id) => {
        console.log("cambcatete");
        getIdProducto1(id);
        //getIdCategoria(categoria);
    }

    let productosPaginacion = productos.slice(rango1, rango2);

    return (
        <section id="planes" className='planes margen1 bg-[#EEF2FF] dark:bg-[#141828]'>

            <h1 className=' text-center titulo1 mb-4'>Planes</h1>




            <div className='grid gap-7 grid-cols-1 lg:grid-cols-3 pt-5'>
                {
                    productosPaginacion.map((producto) =>

                        <div className="rounded-2xl bg-[#F8FAFF] dark:bg-[#070c21] py-10 text-center ring-1 ring-inset ring-[#0962ba] justify-center py-16">
                            <div className="mx-auto  px-8">
                                <div className='flex justify-center  items-center'>
                                    <div><img src={process.env.REACT_APP_URL_FOLDER + "/./imagenes/productos/" + producto.id + "/" + JSON.parse(producto.imagenes)[0]} class="img-plan" alt="..." /></div>
                                    <div className='pl-2'><p className="text-base font-semibold text-white-600 text-lg">
                                        {primeraLetraMayuscula(producto.nombre)}</p>
                                    </div>
                                </div>



                                <p className="mt-6 flex items-baseline justify-center gap-x-2">

                                    {
                                        preciosVariables.length > 0 &&
                                        <>
                                            <span className="text-5xl font-bold tracking-tight text-white-900">
                                                ${PreciosPlanes(producto.id, 0)}

                                            </span>
                                            <span className="text-2xl font-bold tracking-tight text-white-900">ARS</span>
                                        </>
                                    }

                                </p>


                                < Link to={process.env.REACT_APP_URL_FOLDER + "/carrito"} onClick={() => handleClick(producto.nombre, PreciosPlanes(producto.id), producto.id, JSON.parse(producto.imagenes)[0], producto.nombre_unidad)} className='boton2' >
                                    {producto.id == 1 ?
                                        <>Solicitar</>
                                        :
                                        <>Comprar</>
                                    }

                                </Link>


                                {producto.id == 2 &&
                                    <>
                                        <p className='text-sm text-white-600  mt-6  text-left dark:font-[10]'>
                                            Comprá tu sitio web y gana presencia en internet.

                                        </p>



                                    </>
                                }
                                {producto.id == 3 &&
                                    <>
                                        <p className='text-sm text-white-600 mt-6   text-left dark:font-[10]'>
                                            Comprá tu tienda virtual y genera más ventas con publicidad.

                                        </p>


                                    </>
                                }




                                <ul className=' mt-6  text-sm text-white-600 list-inside list-disc text-left'>
                                    {JSON.parse(producto.descripcion).map((item) =>
                                        <>



                                            <li className='list-none pb-4 flex'>
                                                <div className='flex'>



                                                    {producto.id !== 1 &&
                                                        <div className='flex-none w-5 mr-3'>
                                                            {item[0] == 1 ?
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className='text-[#274dd8]' >
                                                                    <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                                                </svg>
                                                                :
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="text-[#cb1515]">
                                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"></path>
                                                                </svg>
                                                            }
                                                        </div>
                                                    }

                                                    <div>
                                                        <div>
                                                            <p className='font-semibold dark:font-[10]'>{item[1]}
                                                                {/*JSON.parse(item).map((x) =>
                                                                <div>{x}</div>

                                                             )*/}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>


                                            </li>
                                        </>
                                    )}


                                </ul>

                                {producto.id == 3 &&
                                    <><br />

                                        <a href="https://www.tiendabrando.com.ar" target='_blank' className='underline decoration-[#274dd8]  hover:decoration-[#fff] text-sm text-white-600'>
                                            Ver Tienda virtual
                                        </a>


                                    </>
                                }

                            </div>
                        </div>


                    )
                }

            </div >


        </section >
    )
}